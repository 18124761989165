<template>
  <v-footer class="mt-8" id="contact" padless color="transparent">
    <v-container class="white--text">
      <v-row>
        <v-col cols="12" sm="4">
          <ColorCircle color="white" />
          <div style="float:left;clear:both" class="">
            <h2>Kontakt</h2>
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <!--<b>Postanschrift</b>
          <p>
            Körber-Stiftung | Kehrwieder 12, 20457 Hamburg
          </p>-->
          <b>Mail</b>
          <p>
            timo@codeforhamburg.org
          </p>
        </v-col>
        <v-col cols="12" sm="4" class="text-sm-right">
          <!--#FOS2022-->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          In Kooperation mit Code for Hamburg e.V.
        </v-col>
        <v-col cols="12" sm="6" class="text-sm-right">
          <v-btn to="/impressum" text class="pa-0 white--text" >Impressum & Datenschutz</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'Footer',
  components: {
    ColorCircle
  }
}
</script>

<style lang="scss">

</style>
