<template>
  <v-app style="background-color: #1F446C">
    <Header />

    <v-main>
      <router-view/>
    </v-main>
    
    <Footer />
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">

.v-btn {
  text-transform: none;
}

</style>
