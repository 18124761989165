<template>
  <v-card >
    <v-card-text v-if="event">
      <v-row no-gutters>
        <v-col cols="12">
          <CircleColor color="#1F446C" />
          <div style="font-size: small; height: 40px;">
            Event<br />
            {{event.datetime}}
          </div>
        </v-col>
        <v-col cols="12" class="pa-2">
          <h2>{{event.headline}}</h2>
          <div v-html="event.description" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'EventCard',
  props: ['event'],
  components: {
    ColorCircle
  },
  data: () => ({
  }),
}
</script>

