<template>
<span>
  <v-row align="center" no-gutters>
    <v-col cols="12">
      <ColorCircle color="white" />
    </v-col>
    <v-col cols="12" md="6">
      <h2 class="white--text">Programm</h2>
    </v-col>
  </v-row>
  <v-row align="start" justify="space-around" class="ma-0">
    <v-col cols="12" v-if="$vuetify.breakpoint.mobile"> <!-- MOBILE ONLY -->
      <v-timeline dense>
        <v-timeline-item large fill-dot v-for="time in computedCalendarKeysAll" :key="time" :color="exhibitor_type_colors[computedCalendar[time][0].type]">
          <template v-slot:icon>
            <span class="h2 white--text">{{formatTime(time)}}</span>
          </template>
          <v-row class="ma-0">
            <v-col cols="12" md="6" v-for="(item,index) in computedCalendar[time]" :key="index">
              <router-link v-if="!item.unclickable" :to="'/aussteller/' + item.id" target="_blank" style="text-decoration: none; color: inherit;">
                <v-card>
                  <v-card-title class="text-caption" style="word-break: unset">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </router-link>
              <span v-else>
                <v-card>
                  <v-card-title class="text-caption">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col cols="6" v-if="!$vuetify.breakpoint.mobile">
      <v-timeline dense>
        <v-timeline-item large fill-dot v-for="time in computedCalendarKeysLeft" :key="time" :color="exhibitor_type_colors[computedCalendar[time][0].type]">
          <template v-slot:icon>
            <span class="h2 white--text">{{formatTime(time)}}</span>
          </template>
          <v-row>
            <v-col cols="12" md="6" v-for="(item,index) in computedCalendar[time]" :key="index">
              <router-link v-if="!item.unclickable" :to="'/aussteller/' + item.id" target="_blank" style="text-decoration: none; color: inherit;">
                <v-card>
                  <v-card-title class="text-caption">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </router-link>
              <span v-else>
                <v-card>
                  <v-card-title class="text-caption">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-col>
    <v-col cols="6" v-if="!$vuetify.breakpoint.mobile">
      <v-timeline dense>
        <v-timeline-item large fill-dot v-for="time in computedCalendarKeysRight" :key="time" :color="exhibitor_type_colors[computedCalendar[time][0].type]">
          <template v-slot:icon>
            <span class="h2 white--text">{{formatTime(time)}}</span>
          </template>
          <v-row>
            <v-col cols="12" md="6" v-for="(item,index) in computedCalendar[time]" :key="index">
              <router-link v-if="!item.unclickable" :to="'/aussteller/' + item.id" target="_blank" style="text-decoration: none; color: inherit;">
                <v-card>
                  <v-card-title class="text-caption">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </router-link>
              <span v-else>
                <v-card>
                  <v-card-title class="text-caption">
                   <span v-if="exhibitor_type_names[item.type]">{{exhibitor_type_names[item.type]}}: {{item.headline}}</span>
                   <span v-else>{{item.headline}}</span>
                  </v-card-title>
                  <v-card-text>
                    <b>{{item.name}}</b>
                  </v-card-text>
                </v-card>
              </span>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-col>
  </v-row>
</span>
</template>

<script>

import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'TimelineCard',
  components: {
    ColorCircle
  },
  data: () => ({
    calendar: null,
    exhibitor_type_colors:{ 'speaker': '#B62234',
                          'panel': '#E1C721',
                          'organisation': '#59863C',
                          'project': '#1F446C',
                          'deep_dive': '#59863C',
                          'keynote': '#B62234',
                          'greeting': '#E1C721'},
    exhibitor_type_names:{  'speaker': 'Speaker:innen',
                            'panel': 'Panel',
                            'organisation': 'Organisation',
                            'project': 'Projekt',
                            'deep_dive': 'Deep Dive',
                            'keynote': 'Keynote',
                            'greeting': 'Begrüßung'}
  }),
  async created() {
    let temp = await this.$client.items("exhibitors").readMany({ fields: ['id, headline, name, type, start_time'], filter: {'start_time': {'_nnull':true}}, sort: 'start_time'})
    this.calendar = temp.data
  },
  computed: {
    computedCalendar: function() {
    
      if (this.calendar == null) return []
      
      let content = { '11:00': [{headline: 'Kaffeepause', 'start_time':'11:00:00', 'unclickable':true}],
                      '13:00': [{headline: 'Mittagspause', 'start_time':'13:00:00', 'unclickable':true}],
                      '14:45': [{headline: 'Kaffeepause', 'start_time':'14:45:00', 'unclickable':true}]}
      
      this.calendar.forEach(event => {
        
        let datetime = new Date('2021-01-01T' + event.start_time.padStart(8, '0'))
        let time =  String(datetime.getHours()).padStart(2, '0') + ':' + String(datetime.getMinutes()).padStart(2, '0');

        if (!content[time]) content[time] = []
        content[time].push(event)
      })

      return content
    },
    computedCalendarKeysLeft : function () {
      if (this.computedCalendar == null) return null
      
      let temp =Object.keys(this.computedCalendar).sort()

      temp = temp.filter(item => item <= '13:00')

      return temp
    },
    computedCalendarKeysRight : function () {
      if (this.computedCalendar == null) return null
      
      let temp =Object.keys(this.computedCalendar).sort()

      temp = temp.filter(item => item > '13:00')

      return temp
    },
    computedCalendarKeysAll : function () {
      if (this.computedCalendar == null) return null
      
      let temp =Object.keys(this.computedCalendar).sort()

      return temp
    },
  },
  methods: {
    formatTime: function(time) {
      let datetime = new Date('2021-01-01T' + time)
      return datetime.getHours() + ':' + String(datetime.getMinutes()).padStart(2, '0');
    }
  }
}
</script>

<style lang="scss">

.v-card__title {
  word-break: unset;
}

.v-card {
  height: 100%;
}

</style>