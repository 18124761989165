<template>
  <div class="my-3" :style="'float:left; height: 40px; width: 40px; border-radius: 50%; border: solid 6px ' + color + ';display: inline-block;'">&nbsp;</div>
</template>

<script>

export default {
  name: 'ColorCircle',
  props: ['color'],
  data: () => ({
  }),
}
</script>