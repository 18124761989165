<template>
  <v-card outlined :color="cardColor">
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12" >
          <ColorCircle :color="circleColor" />
        </v-col>
        <v-col :cols="image ? 6 : 12" class="pa-2 pl-0" :style="'color:' + fontColor" :order="image_position == 'right' ? 1: 2">
          <h2>{{headline}}</h2>
          <div v-html="content"  class="mt-2">
          </div>
        </v-col>
        <v-col cols="6" v-if="image" :order="image_position == 'right' ? 2: 1">
          <v-img class="rounded ma-3"  style="margin:auto;" :src="'https://directus.offenestadt.info/assets/' + image.id" :title="image.title" max-height="725px" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ColorCircle from '@/components/ColorCircle.vue'

export default {
  name: 'ContentCard',
  props: ['headline', 'content', 'card-color', 'circle-color', 'font-color', 'image', 'image_position'],
  components: {
    ColorCircle
  },
  data: () => ({
  }),
}
</script>

