import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import(/* webpackChunkName: "Imprint" */ '../views/Imprint.vue')
  },
  {
    path: '/ueber-uns',
    name: 'ÜberUns',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/AboutUs.vue')
  },
  {
    path: '/rueckblick',
    name: 'Rückblick',
    component: () => import(/* webpackChunkName: "AboutUs" */ '../views/Review.vue')
  },
  {
    path: '/slack',
    name: 'Slack',
    component: () => import(/* webpackChunkName: "Slack" */ '../views/Slack.vue')
  },
  {
    path: '/exhibitor-overview',
    alias: '/ausstellerkatalog',
    name: 'Ausstellerübersicht',
    component: () => import(/* webpackChunkName: "exhibitor-overview" */ '../views/ExhibitorOverview.vue')
  },
  {
    path: '/exhibitor/:id',
    alias: '/aussteller/:id',
    name: 'Aussteller',
    component: () => import(/* webpackChunkName: "exhibitor" */ '../views/Exhibitor.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function(to, from, savedPosition) {

    if (to.fullPath == '/') {
  
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve ({ selector: '#livestream', behavior: 'smooth', });
        }, 1000);
      })
    }
    else if (to.hash) {

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve ({ selector: to.hash, behavior: 'smooth', });
        }, 1000);
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
