<template>
  <div>
    <v-app-bar app flat height="96" color="rgba(31,68,108,1)">
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile" @click="drawer = true" color="white"></v-app-bar-nav-icon>
        <v-container class="pa-0">
          <v-row  v-if="!$vuetify.breakpoint.mobile" justify="space-around" align="end">
            <router-link to="/" style="text-decoration: none; color: inherit;">
              <img src="../assets/logos/KOB_FOS_logo_wht.svg" height="80px" />
            </router-link>
            <!--<img class="ma-3" src="../assets/logos/CodeForHamburg.svg" height="60px" />
            <img class="ma-3" src="../assets/logos/Logo_Koerber-Stiftung_weiss.png" height="60px" />-->
            
            <v-spacer />
            <v-btn class="white--text" active-class="selected" text to="/">Start</v-btn>
            <v-btn class="white--text" active-class="selected" text to="/rueckblick">Rückblick</v-btn>
            <v-btn class="white--text" active-class="selected" text to="/ueber-uns">Über uns</v-btn>
            <v-btn class="white--text" active-class="selected" text to="/exhibitor-overview">Teilnehmer:innen</v-btn>
            <!--<v-btn class="white--text" active-class="selected" text to="/slack">Slack</v-btn>-->
            <!--<v-btn class="white--text" active-class="selected" text to="/#program">Programm</v-btn>-->
            <v-btn class="white--text" active-class="selected" text to="/#contact">Kontakt</v-btn>
          </v-row>
          <router-link to="/" style="text-decoration: none; color: inherit;">
            <img v-if="$vuetify.breakpoint.mobile" src="../assets/logos/KOB_FOS_logo_wht.svg" height="60px" />
          </router-link>
        </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary color="rgba(31,68,108,1)">
      <v-list nav dense>
        <v-list-item>
          <v-btn class="ma-2 white--text" text to="/">Start</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="ma-2 white--text" text to="/exhibitor-overview">Teilnehmer:innen</v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn class="ma-2 white--text" text to="/ueber-uns">Über uns</v-btn>
        </v-list-item>
        <!--<v-list-item>
          <v-btn class="ma-2 white--text" text to="/#program">Programm</v-btn>
        </v-list-item>-->
        <v-list-item>
          <!--<v-btn class="ma-2 white--text" text to="#kontakt">Kontakt</v-btn>-->
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>

export default {
  name: 'Header',
  data: () => ({
      drawer: false,
      group: null,
    }),
}
</script>

<style lang="scss">
  .selected {
    border-bottom: solid 2px #E1C721;
  }
</style>
